import bg from '../../../assets/images/landing/stars-bg.webp';
import dices from '../../../assets/images/icons/dices.webp';
import jelly from '../../../assets/images/icons/jelly.webp';
import './Pros.css';
import { PROS_LIST } from '../../../assets/utils/constants';

function Pros() {
  return (
    <div className="pros">
      <div className="pros__container">
        <p className="pros__title">
          100% On-chain.
          <br />
          100% Fair.
        </p>
        <ul className="pros__list">
          {PROS_LIST.map((item) => (
            <li className="pros__item-card" key={item.title}>
              <img className="pros__card-img" src={item.img} alt="" />
              <p className="pros__card-title">{item.title}</p>
              <p className="pros__card-text">{item.text}</p>
            </li>
          ))}
        </ul>
      </div>

      <img className="pros__bg" src={bg} alt="" />
      <img className="pros__dices-img" src={dices} alt="" />
      <img className="pros__jelly-img" src={jelly} alt="" />
    </div>
  );
}

export default Pros;
