import plinko from '../images/landing/games/plinko.webp';
import roulette from '../images/landing/games/roulette.webp';
import dice from '../images/landing/games/mega-dice.webp';
import limbo from '../images/landing/games/limbo.webp';
import coins from '../images/landing/games/coin-flip.webp';
import dragon from '../images/landing/games/dragon.webp';
import sevens from '../images/landing/games/lucky-sevens.webp';
import wheel from '../images/landing/games/lucky-wheel.webp';
import shuttle from '../images/landing/games/shuttle.webp';
import crush from '../images/landing/games/crush.webp';

import card1 from '../images/landing/pros/card1.webp';
import card2 from '../images/landing/pros/card2.webp';
import card3 from '../images/landing/pros/card3.webp';

import {
  DiscordIcon,
  SomeLogoIcon,
  TelegramIcon,
  TwitterIcon,
} from '../icons/icons';

export const GAMES_LIST = [
  {
    title: 'Coin Flip',
    text: `Guess Heads or Tails
    to double your bet`,
    image: coins,
  },
  {
    title: 'Plinko',
    text: `Drop the ball and
    watch it bounce`,
    image: plinko,
  },
  {
    title: 'Shuttle',
    text: `Try your luck in the
    most popular classic game`,
    image: shuttle,
  },
  {
    title: 'Mega Dice',
    text: `Choose Over or Under, roll
    dice and claim your prize`,
    image: dice,
  },
  {
    title: 'Limbo',
    text: `Try your luck in the
    most popular classic game`,
    image: limbo,
  },
  {
    title: 'Crush',
    text: `Try your luck in the
    most popular classic game`,
    image: crush,
  },
  {
    title: 'Dragon',
    text: `Try your luck in the
    most popular classic game`,
    image: dragon,
  },
  {
    title: 'Lucky Sevens',
    text: `Try your luck in the
    most popular classic game`,
    image: sevens,
  },
  {
    title: 'Lucky Wheel',
    text: `Try your luck in the
    most popular classic game`,
    image: wheel,
  },
  {
    title: 'Roulette',
    text: `Try your luck in the
    most popular classic game`,
    image: roulette,
  },
];

export const PROS_LIST = [
  {
    img: card1,
    title: 'No deposits',
    text: 'Users play directly from Web3 wallets and never lose control over their funds',
  },
  {
    img: card2,
    title: 'No rigs',
    text: 'All games and odds are inscribed into smart contracts and verifiable on chain',
  },
  {
    img: card3,
    title: 'No bullshit',
    text: 'No more KYCs, withdrawal bans, and other Web2 iGaming headaches for the players',
  },
];

export const FOOTER_LINKS = [
  {
    title: 'Terms',
    link: '#',
  },
  {
    title: 'Privacy',
    link: '#',
  },
  {
    title: 'Cookie Policy',
    link: '#',
  },
];

export const FOOTER_SOCIAL_LINKS = [
  // {
  //   icon: TelegramIcon,
  //   link: '#',
  // },
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/jellybetio',
  },
  // {
  //   icon: SomeLogoIcon,
  //   link: '#',
  // },
  // {
  //   icon: DiscordIcon,
  //   link: '#',
  // },
];


export const PLAY_FRONTEND_LINK = 'https://play.jellygames.io'