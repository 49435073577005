import './GameCard.css';

function GameCard({ game }) {
  return (
    <div className="game-card">
      <div className="game-card__return">
        <p className="game-card__return-text">X1000 return</p>
      </div>
      <div className="game-card__text-block">
        <p className="game-card__title">{game.title}</p>
        <p className="game-card__card-text">{game.text}</p>
      </div>
      <img className="game-card__image" src={game.image} alt={game.title} />
    </div>
  );
}

export default GameCard;
