import { JellyLogo, LayerZeroLogo } from '../../assets/icons/icons';
import {
  FOOTER_LINKS,
  FOOTER_SOCIAL_LINKS,
} from '../../assets/utils/constants';
import bg from '../../assets/images/landing/stars-bg.webp';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__logos-block">
          <JellyLogo mainClassName="footer__logo" />
          <div className="footer__powered-box">
            <p className="footer__powered-text">Powered by</p>
            <LayerZeroLogo
              mainClassName="footer__powered-logo"
              fillClassName="footer__powered-logo-fill"
            />
          </div>
        </div>

        <div className="footer__links-block">
          <ul className="footer__links-list">
            {/* {FOOTER_LINKS.map((item) => (
              <li className="footer__list-item" key={item.title}>
                <a
                  className="footer__link"
                  href={`/${item.link}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.title}
                </a>
              </li>
            ))} */}
          </ul>

          <ul className="footer__social-list">
            {FOOTER_SOCIAL_LINKS.map((item, i) => (
              <li className="footer__list-item" key={i}>
                <a
                  className="footer__social-link"
                  href={`${item.link}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.icon({
                    mainClassName: 'footer__social-icon',
                    fillClassName: 'footer__social-icon-fill',
                  })}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <img className="footer__bg" src={bg} alt="" />
    </footer>
  );
}

export default Footer;
