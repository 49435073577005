import Games from './Games/Games';
import Heading from './Heading/Heading';
import './Landing.css';
import Pros from './Pros/Pros';
import Scheme from './Scheme/Scheme';
import Token from './Token/Token';

function Landing() {
  return (
    <main className="main">
      <Heading />
      <Games />
      <Pros />
      <Scheme />
      <Token />
    </main>
  );
}

export default Landing;
