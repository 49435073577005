import Footer from '../Footer/Footer';
import Landing from '../Landing/Landing';
import './App.css';

function App() {
  return (
    <div className="app">
      <Landing />
      <Footer />
    </div>
  );
}

export default App;
