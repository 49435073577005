import {
  JellyLogo,
  LayerZeroLogo,
  StarIcon,
} from '../../../assets/icons/icons';
import JoinBtn from '../../JoinBtn/JoinBtn';
import bg from '../../../assets/images/landing/stars-bg.webp';
import star from '../../../assets/images/icons/star.webp';
import ship from '../../../assets/images/icons/starship.webp';
import coins from '../../../assets/images/icons/coins.webp';
import heart from '../../../assets/images/icons/heart.webp';
import disc from '../../../assets/images/icons/disc.webp';
import dices from '../../../assets/images/icons/dices.webp';
import shuttle from '../../../assets/images/icons/shuttle.webp';
import './Heading.css';

function Heading() {
  return (
    <div className="heading">
      <div className="heading__container">
        <JellyLogo mainClassName="heading__logo" />
        <h1 className="heading__title">
          Omnichain
          <br />
          iGmaing Platform
        </h1>
        <div className="heading__join-btn-box">
          <JoinBtn />
          <div className="heading__btn-sticker">
            <StarIcon
              mainClassName="heading__sticker-icon"
              fillClassName="heading__sticker-icon-fill"
            />
            <p className="heading__sticker-text">
              Earn
              <br />
              Points
            </p>
          </div>
        </div>
        <div className="heading__powered-box">
          <p className="heading__powered-text">Powered by</p>
          <LayerZeroLogo
            mainClassName="heading__powered-logo"
            fillClassName="heading__powered-logo-fill"
          />
        </div>
      </div>

      <img className="heading__bg" src={bg} alt="" />
      <div className="heading__bg-circle heading__bg-circle_position_left" />
      <div className="heading__bg-circle heading__bg-circle_position_right" />
      <img className="heading__star-img" src={star} alt="" />
      <img className="heading__ship-img" src={ship} alt="" />
      <img className="heading__coins-img" src={coins} alt="" />
      <img className="heading__heart-img" src={heart} alt="" />
      <img className="heading__disc-img" src={disc} alt="" />
      <img className="heading__dices-img" src={dices} alt="" />
      <div className="heading__shuttle-box">
        <img className="heading__shuttle-img" src={shuttle} alt="" />
        <div className="heading__shuttle-blackout" />
      </div>
    </div>
  );
}

export default Heading;
