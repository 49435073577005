import { PLAY_FRONTEND_LINK } from '../../assets/utils/constants';
import './JoinBtn.css';

function JoinBtn() {
  return (
    <a className="join-btn"  href={PLAY_FRONTEND_LINK}>
      <p className="join-btn__text">Join Early Access</p>
    </a>
  );
}

export default JoinBtn;
