import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import GameCard from '../GameCard/GameCard';
import './GamesSwiper.css';
import 'swiper/css';

function GamesSwiper({ data }) {
  return (
    <Swiper
      className="games-swiper"
      slidesPerView="auto"
      speed={500}
      loop={true}
      modules={[Mousewheel]}
      preventInteractionOnTransition={true}
      mousewheel={{ forceToAxis: true }}
      grabCursor={true}
      spaceBetween={10}
      breakpoints={{
        901: {
          spaceBetween: 16,
        },
      }}
    >
      {data.map((item, i) => (
        <SwiperSlide key={`${item.title}${i}`}>
          <GameCard game={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default GamesSwiper;
