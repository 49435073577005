import GamesSwiper from './GamesSwiper/GamesSwiper';
import { GAMES_LIST } from '../../../assets/utils/constants';
import './Games.css';
import 'swiper/css';

function Games() {
  return (
    <div className="games">
      <div className="games__container">
        <GamesSwiper
          data={[...GAMES_LIST.slice(0, 5), ...GAMES_LIST.slice(0, 5)]}
        />
        <GamesSwiper data={[...GAMES_LIST.slice(5), ...GAMES_LIST.slice(5)]} />
      </div>

      <div className="games__container games__container_mobile">
        <GamesSwiper
          data={[...GAMES_LIST.slice(0, 3), ...GAMES_LIST.slice(0, 3)]}
        />
        <GamesSwiper
          data={[...GAMES_LIST.slice(3, 7), ...GAMES_LIST.slice(3, 7)]}
        />
        <GamesSwiper data={[...GAMES_LIST.slice(7), ...GAMES_LIST.slice(7)]} />
      </div>
    </div>
  );
}

export default Games;
