import JoinBtn from '../../JoinBtn/JoinBtn';
import bg from '../../../assets/images/landing/stars-bg.webp';
import banner from '../../../assets/images/landing/banner-img.webp';
import './Token.css';

function Token() {
  return (
    <div className="token">
      <div className="token__container">
        <div className="token__content">
          <div className="token__text-block">
            <p className="token__title">
              Earn JellyPoints
              <br />
              for $JELLY Airdrop
            </p>
            <p className="token__text">
              Omnichain Token for
              <br />
              Omnichain Rewards
            </p>
            <JoinBtn />
          </div>

          <div className="token__img-block">
            <img className="token__img" src={banner} alt="" />
            <div className="token__img-blackout token__img-blackout_position_top" />
            <div className="token__img-blackout token__img-blackout_position_right" />
            <div className="token__img-blackout token__img-blackout_position_left" />
          </div>
        </div>
      </div>

      <img className="token__bg" src={bg} alt="" />
    </div>
  );
}

export default Token;
