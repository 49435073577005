import scheme from '../../../assets/images/landing/scheme.webp';
import './Scheme.css';

function Scheme() {
  return (
    <div className="scheme">
      <p className="scheme__title">Choose your side</p>
      <p className="scheme__subtitle">Play and win or be the House and earn</p>
      <img className="scheme__img" src={scheme} alt="" />
    </div>
  );
}

export default Scheme;
